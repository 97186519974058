import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import useStyles from "components/Dialog/Dialog.styles";

export default function PaymentModal() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!document.querySelector('script[src="https://checkout.epayco.co/checkout.js"]')) {
      const script = document.createElement("script");
      script.src = "https://checkout.epayco.co/checkout.js";
      script.async = true;
      script.onload = () => console.log("ePayco cargado");
      document.body.appendChild(script);
    }
  }, []);

  const handlePayment = () => {
    setIsOpen(false);
    if (window.ePayco) {
      const handler = window.ePayco.checkout.configure({
        key: process.env.REACT_APP_PUBLIC_KEY_EPAYCO,
        test: true,
      });
      handler.open({
        name: "Paga tu trámite",
        description: "Pago de trámite en ePayco",
        invoice: "INV-" + Date.now(),
        currency: "COP",
        amount: "7000",
        tax_base: "0",
        tax: "0",
        country: "CO",
        lang: "es",
        external: "false",
        confirmation: "https://sinfony.org",
        response: "https://sinfony.org",
      });
    } else {
      console.error("ePayco no ha sido cargado aún");
    }
    /*
      response: Página de respuesta personalizada ó dinámica
      acepted: Url estática para transacción aceptada
      rejected: Url estática para transacción rechazada
      pending: Url estática para transacción pendiente
      confirmation: Página de confirmación dinámica
     */
  };

  return (
    <div style={{ padding: "20px" }}>
      <Button color="primary" onClick={() => setIsOpen(true)}>
        Pagar
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} disableEnforceFocus disableAutoFocus >
        <DialogTitle>
          <div className={classes.titleModal}>
            <p className={classes.text}> Pago con ePayco</p>
          </div>
        </DialogTitle>
        <DialogContent>
          <p> ¿Quieres realizar el pago del {`tramite`} ahora mismo?</p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handlePayment}>
            Pagar
          </Button>
          <Button color="danger" onClick={() => setIsOpen(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
