import { Form, Table } from 'antd'
import FormItem from 'components/Custom/FormCommon'
import React, { useEffect, useRef } from 'react'

//Styles
import useStyles from '../../assets/jss/material-dashboard-pro-react/views/common'
import useTable from 'hooks/useTable'
import EditableCell from 'components/Custom/EditableCell'
import { columns } from 'utils/columnCommon'

const title = 'Tipo de inmueble'
const name = 'Tipo de inmueble'
const key = 'tipoInmueble'

const TipoInmuebles = () => {
  const classes = useStyles()
  const searchInput = useRef()
  const {
    formEdit,
    formCreate,
    data,
    loading,
    onEdit,
    onDelete,
    updateOnEdit,
    createItem,
    editItem,
    delItem,
    save,
    isEditing,
    cancel,
    onFinishFailed,
    updateNameItem,
  } = useTable({ key })

  useEffect(() => {
    updateNameItem(name)
  }, [])

  const dataWithKey = data.map((item) => ({
    ...item,
    key: item.id,
  }));

  return (
    <>
      <FormItem
        name={name}
        title={title}
        maxName={30}
        maxCode={5}
        formCreate={formCreate}
        createItem={createItem}
        onFinishFailed={onFinishFailed}
        loading={loading}
        isTypeInmueble={true}
      />
      <br />
      <Form form={formEdit} component={false}>
        <Table
          className={classes.table}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          size="sm"
          bordered
          scroll={{ x: 500 }}
          dataSource={dataWithKey}
          columns={columns(
            cancel,
            isEditing,
            updateOnEdit,
            save,
            delItem,
            editItem,
            onEdit,
            onDelete,
            name,
            searchInput,
            classes
          )}
          loading={loading}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
            pageSize: 5,
          }}
        />
      </Form>
    </>
  )
}

export default TipoInmuebles

//Codigo con mejoras
// import React, { useState, useCallback } from "react";
// import PropTypes from "prop-types";
// import { Form } from "antd";
// import FormLabel from "@material-ui/core/FormLabel";
// import Close from "@material-ui/icons/Close";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardText from "components/Card/CardText.js";
// import CardBody from "components/Card/CardBody.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
// import useStyles from "../../assets/jss/material-dashboard-pro-react/views/common";

// const FormItem = ({ title, createItem, onFinishFailed, maxName, maxCode, loading }) => {
//   const [values, setValues] = useState({ name: "", code: "" });
//   const [valuesState, setValuesState] = useState({ name: "", code: "" });
//   const classes = useStyles();

//   const verifyLength = (value, length) => value.length <= length;

//   const validateValues = useCallback(() => {
//     const fields = ["name", "code"];
//     const isValid = fields.every((field) => valuesState[field] === "success");

//     setValuesState((state) =>
//       fields.reduce((acc, field) => ({
//         ...acc,
//         [field]: state[field] !== "success" ? "error" : state[field],
//       }), state)
//     );

//     return isValid;
//   }, [valuesState]);

//   const handleInputChange = (e) => {
//     const { id, value } = e.target;
//     setValues((prev) => ({ ...prev, [id]: value }));

//     setValuesState((prev) => ({
//       ...prev,
//       [id]: verifyLength(value, id === "name" ? maxName : maxCode) ? "success" : "error",
//     }));
//   };

//   const add = () => {
//     if (validateValues()) {
//       const send = createItem(values);
//       if (send) {
//         setValues({ name: "", code: "" });
//         setValuesState({ name: "", code: "" });
//         alert("Ítem agregado correctamente");
//       }
//     } else {
//       onFinishFailed();
//     }
//   };

//   return (
//     <Card>
//       <CardHeader color="primary" text>
//         <CardText className={classes.cardText} color="primary">
//           <h4 className={classes.colorWhite}>{title}</h4>
//         </CardText>
//       </CardHeader>
//       <CardBody>
//         <form>
//           <GridContainer alignItems="center" className={classes.formStyle}>
//             <GridItem xs={12} sm={2}>
//               <FormLabel className={classes.label}>
//                 <span className={classes.colorRose}>*</span> Nombre
//               </FormLabel>
//             </GridItem>
//             <GridItem xs={12} sm={10}>
//               <CustomInput
//                 success={valuesState.name === "success"}
//                 error={valuesState.name === "error"}
//                 id="name"
//                 formControlProps={{ fullWidth: true }}
//                 inputProps={{
//                   onChange: handleInputChange,
//                   type: "text",
//                   maxLength: maxName,
//                   autoComplete: "off",
//                   endAdornment: valuesState.name === "error" && (
//                     <InputAdornment position="end">
//                       <Close className={classes.danger} />
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </GridItem>
//             <GridItem xs={12} sm={12}>
//               <Form.Item className={classes.justifyContentCenter}>
//                 <Button
//                   disabled={loading || !validateValues()}
//                   className={classes.center}
//                   color="rose"
//                   onClick={add}
//                   size="sm"
//                 >
//                   Añadir
//                 </Button>
//               </Form.Item>
//             </GridItem>
//           </GridContainer>
//         </form>
//       </CardBody>
//     </Card>
//   );
// };

// FormItem.propTypes = {
//   title: PropTypes.string.isRequired,
//   createItem: PropTypes.func.isRequired,
//   onFinishFailed: PropTypes.func.isRequired,
//   maxCode: PropTypes.number.isRequired,
//   maxName: PropTypes.number.isRequired,
//   loading: PropTypes.bool.isRequired,
// };

// export default FormItem;
