import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Formulario from './Formulario';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Button, Typography } from '@material-ui/core';
import { EXTERNAL_API_PATHS } from 'utils/constants';
import moment from 'moment';

function App({ dataJson, idPlantilla, idTramite,nameFile, reqDataTramite }) {
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [formData, setFormData] = useState({});
  const [tiposInmuebles, setTiposInmuebles] = useState([]);
  const [estadosCiviles, setEstadosCiviles] = useState([]);
  const [nombresNotarios, setNombresNotarios] = useState([]);
  const [nombresNotariosFirma, setNombresNotariosFirma] = useState([]);
  const [bonos, setBonos] = useState([]);
  const [subsidios, setSubsidios] = useState([]);
  const [typeIds, setTypeIds] = useState([]);

  // Fetch projects from API
  useEffect(() => {
    axios
      .get(`${EXTERNAL_API_PATHS.plantillaDocumentos}/proyectos`)
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
      });
  }, []);

  // Fetch templates when a project is selected
  useEffect(() => {
    if (selectedProject) {
      const project = projects.find((p) => p.codigo === selectedProject);
      if (project) {
        setTemplates(project.plantilla_documentos);
      }
    }
  }, [selectedProject, projects]);

  // Consultar inmuebles traidos de la orden de escrituracion

  const fetchInmueble = async (id) => {
    return await axios
      .get(EXTERNAL_API_PATHS["inmuebles"]+"?filtro[matricula]="+id)
      .then((respuesta) => {
        if(respuesta.data.length > 0){
            return {
            id: respuesta.data[0].id,
            numero_matricula_inmobiliaria: respuesta.data[0].matricula,
            numeroPredial: respuesta.data[0].numero_prediaal ,
            numeroCatastral: respuesta.data[0].numero_catastral,
            linderos: respuesta.data[0].linderos,
            area: respuesta.data[0].area,
            direccion: respuesta.data[0].direccion,
            avaluo_catastral_prorrata: respuesta.data[0].avaluo,
            coeficiente: respuesta.data[0].coeficiente_ph,
            proyectoId: respuesta.data[0].proyecto?.codigo,
            proyectoName: respuesta.data[0].proyecto?.proyecto,
            id_tipo_inmueble: respuesta.data[0].id_tipo_inmueble,
            numero: respuesta.data[0].numero_inmueble,
          };  
        }else{return {}}  
        })   
  };
  
  useEffect(() => {
    const fetchTiposInmuebles = async () => {
      try {
        const res = await axios.get(EXTERNAL_API_PATHS["tipoInmueble"]);
        setTiposInmuebles(res.data);
      } catch (error) {
        console.error("Error al obtener tipos de inmueble:", error);
      }
    };
  
    fetchTiposInmuebles();
  }, []);

  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["tipoDocIde"]).then((res) => {
      const formatRes = res.data.map((item) => ({ value: item.id, label: item.name }));
      setTypeIds(formatRes);
    });
  }, []);
  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["estadoCivil"]).then((res) => {
      const formatRes = res.data.map((item) => ({ value: item.id, label: item.name, umh: item.umh }));
      setEstadosCiviles(formatRes);
    });
  }, []);
  useEffect(() => {
    axios.get(EXTERNAL_API_PATHS["notariaConfig"]).then((res) => {
      const nombres = res.data.find((opcion) => opcion.variable == "nombre_notario")?.data;
      const json = JSON.parse(nombres);
      setNombresNotarios(json);
      const nombreFirma = res.data.find((opcion) => opcion.variable == "nombre_notario_firma")?.data;
      const jsonFirma = JSON.parse(nombreFirma);
      setNombresNotariosFirma(jsonFirma);
      const bonos = res.data.find((opcion) => opcion.variable == "bonos")?.data;
      const jsonBonos = JSON.parse(bonos);
      setBonos(jsonBonos);
      const subsidios = res.data.find((opcion) => opcion.variable == "subsidio")?.data;
      const jsonSubsidios = JSON.parse(subsidios);
      setSubsidios(jsonSubsidios);
    });
  }, []);
  useEffect(() => {
    const fetchInmueblesData = async () => {
      if (reqDataTramite) {
        setFormData(JSON.parse(reqDataTramite));
      } else if (tiposInmuebles.length > 0) { // Asegurar que los tipos están cargados
        setFormData(mapJsonToFormat(dataJson));
        let registrationNumbers = extractPropertiesAndRegistrations(dataJson["Matrículas"]);
        let inmuebles = [];
  
        for (let item of registrationNumbers) {
          try {
            let data = await fetchInmueble(item);
            if (data) {
              let name = tiposInmuebles.find(tipo => tipo.id === data.id_tipo_inmueble);
              data.nombre = name?.name || "Desconocido"; // Manejo de fallback en caso de no encontrarlo
              inmuebles.push(data);
            }
          } catch (error) {
            console.error("Error al obtener inmueble:", error);
          }
        }

        setFormData((prev) => ({
          ...prev,
          inmuebles,
        }));
      }
    };
  
    fetchInmueblesData();
  }, [reqDataTramite, dataJson, tiposInmuebles]); // Agregar `tiposInmuebles` como dependencia


  // Fetch template document
  useEffect(() => {
    if (idPlantilla) {
      axios
        .get(`${EXTERNAL_API_PATHS.plantillaDocumentos}/${idPlantilla}`)
        .then(async (responsePlantilla) => {
          const response = await axios.get(
            `/api/files/${responsePlantilla.data.url_documento}`,
            { responseType: 'blob' }
          );
          if (response.status === 200) {
            setTemplate(response.data);
            console.log('Plantilla Cargada!');
          } else {
            console.error('Error en la descarga del archivo:', response.statusText);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [idPlantilla]);

  const findRecordById = (array, id) => {
    if (!array || !Array.isArray(array)) {
      console.error("El primer parámetro debe ser un array.");
      return null;
    }
  
    const record = array.find(item => item.id === id);
  
    if (!record) {
      console.error(`No se encontró ningún registro con id: ${id}`);
      return null;
    }
  
    return record;
  };

  // Handle form submit
  const handleGenerateDocument = async () => {
    if (!selectedProject || !selectedTemplate) {
      alert('Por favor, seleccione un proyecto y una plantilla.');
      return;
    }
    setIsLoading(true);
    try {
      const payload = {
        url_plantilla: findRecordById(templates,selectedTemplate).url_documento,
        id: idTramite,
        datos: formData,
        nameFile: nameFile
      };
      const response = await axios.post(`${EXTERNAL_API_PATHS.plantillaDocumentos}/documento`, payload);
      console.log('Response:', response.data);
      getDocument(response.data.output_key);
    } catch (error) {
      console.error('Error al generar el documento:', error);
      alert('Error al generar el documento.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveData = async () => {
    setIsLoading2(true);
    try {
      const payload = {
        req_data_tramite: formData,
      };
      const response = await axios.put(`${EXTERNAL_API_PATHS.tramites}/${idTramite}`, payload);
      alert('Actualizado exitosamente!');
    } catch (error) {
      console.error('Error al generar el documento:', error);
      alert('Error al generar el documento.');
    } finally {
      setIsLoading2(false);
    }
  };

  function extractPropertiesAndRegistrations(registrationNumbers) {
      registrationNumbers = registrationNumbers.length ? registrationNumbers
        .replace(" y ", ",") // Sustituimos "y" por coma para homogeneizar
        .split(",") // Dividimos por comas
        .map(item => item.trim()) // Eliminamos espacios en blanco
        .filter(item => item) // Eliminamos elementos vacíos
        : [];

      return registrationNumbers
  };

  function cleanText(text) {
    return text.replace(/-/g, '').trim();
  }

  function mapJsonToFormat(sourceJson) {
    const compradores = [];
    const vendedores = [];

    // Primer comprador
    if (sourceJson["Nombre Primer comprador"]) {
      compradores.push({
        nombre: cleanText(sourceJson["Nombre Primer comprador"] || ""),
        cedula: sourceJson["No De Cedula Primer comprador"] || "",
        lugar_expedicion: sourceJson["Lugar Expedición Primer comprador"] || "",
        ciudad_residente: sourceJson["Domicilio Primer comprador"] || "",
        estado_civil: sourceJson["Estado civil Primer comprador"] || "",
        nacionalidad: "", // Puede incluirse lógica adicional si se necesita
        tipoDocumento: "", // Ajusta según sea necesario
        telefono: sourceJson["Numero Celular Primer comprador"] || "",
        actividad_economica: sourceJson["Ocupación Primer comprador"] || "",
        domicilio: sourceJson["Domicilio Primer comprador"] || "",
        direccion_domicilio: sourceJson["Dirección Primer comprador"] || "",
        conyugue: {
          nombre: cleanText(sourceJson["En calidad de cónyuge Primer comprador"] || ""),
          identificacion: "", // Ajusta según sea necesario
          lugar_expedicion: "",
          nacionalidad: "", // Ajusta según sea necesario
          tipoDocumento: "", // Ajusta según sea necesario
          telefono: "", // Ajusta según sea necesario
          actividad_economica: "", // Ajusta según sea necesario
          domicilio: "", // Ajusta según sea necesario
          direccion_domicilio: "" // Ajusta según sea necesario
        },
        apoderado: {
          nombre: cleanText(sourceJson["Apoderado Primer comprador"] || ""),
          domicilio: "", // Ajusta según sea necesario
          tipo_documento: "", // Ajusta según sea necesario
          lugar_expedicion: "",
          identificacion: "" // Ajusta según sea necesario
        }
      });
    }

    // Segundo comprador
    if (sourceJson["Nombre Segundo comprador"] && sourceJson["Nombre Segundo comprador"] !== "---") {
      compradores.push({
        nombre: cleanText(sourceJson["Nombre Segundo comprador"] || ""),
        cedula: sourceJson["No De Cedula Segundo comprador"] || "",
        lugar_expedicion: sourceJson["Lugar Expedición Segundo comprador"] || "",
        ciudad_residente: sourceJson["Domicilio Segundo comprador"] || "",
        estado_civil: sourceJson["Estado civil Segundo comprador"] || "",
        nacionalidad: "", // Puede incluirse lógica adicional si se necesita
        tipoDocumento: "", // Ajusta según sea necesario
        telefono: sourceJson["Numero Celular Segundo comprador"] || "",
        actividad_economica: sourceJson["Ocupación Segundo comprador"] || "",
        domicilio: sourceJson["Domicilio Segundo comprador"] || "",
        direccion_domicilio: sourceJson["Dirección Segundo comprador"] || "",
        conyugue: {
          nombre: cleanText(sourceJson["En calidad de cónyuge Segundo comprador"] || ""),
          identificacion: "", // Ajusta según sea necesario
          nacionalidad: "", // Ajusta según sea necesario
          lugar_expedicion: "",
          tipoDocumento: "", // Ajusta según sea necesario
          telefono: "", // Ajusta según sea necesario
          actividad_economica: "", // Ajusta según sea necesario
          domicilio: "", // Ajusta según sea necesario
          direccion_domicilio: "" // Ajusta según sea necesario
        },
        apoderado: {
          nombre: cleanText(sourceJson["Apoderado Segundo comprador"] || ""),
          domicilio: "", // Ajusta según sea necesario
          tipo_documento: "", // Ajusta según sea necesario
          lugar_expedicion: "",
          identificacion: "" // Ajusta según sea necesario
        }
      });

    // Primer vendedor
    if (sourceJson["Nombre Primer vendedor"]) {
      vendedores.push({
        nombre: cleanText(sourceJson["Nombre Primer vendedor"] || ""),
        cedula: sourceJson["No De Cedula Primer vendedor"] || "",
        lugar_expedicion: sourceJson["Lugar Expedición Primer vendedor"] || "",
        ciudad_residente: sourceJson["Domicilio Primer vendedor"] || "",
        estado_civil: sourceJson["Estado civil Primer vendedor"] || "",
        nacionalidad: "",
        tipoDocumento: "",
        telefono: sourceJson["Numero Celular Primer vendedor"] || "",
        actividad_economica: sourceJson["Ocupación Primer vendedor"] || "",
        domicilio: sourceJson["Domicilio Primer vendedor"] || "",
        direccion_domicilio: sourceJson["Dirección Primer vendedor"] || "",
        conyugue: {
          nombre: cleanText(sourceJson["En calidad de cónyuge Primer vendedor"] || ""),
          identificacion: "",
          nacionalidad: "",
          tipoDocumento: "",
          lugar_expedicion: "",
          telefono: "",
          actividad_economica: "",
          domicilio: "",
          direccion_domicilio: ""
        },
        apoderado: {
          nombre: cleanText(sourceJson["Apoderado Primer vendedor"] || ""),
          domicilio: "",
          tipo_documento: "",
          lugar_expedicion: "",
          identificacion: ""
        }
      });
    }

    // Segundo vendedor
    if (sourceJson["Nombre Segundo vendedor"] && sourceJson["Nombre Segundo vendedor"] !== "---") {
      vendedores.push({
        nombre: cleanText(sourceJson["Nombre Segundo vendedor"] || ""),
        cedula: sourceJson["No De Cedula Segundo vendedor"] || "",
        lugar_expedicion: sourceJson["Lugar Expedición Segundo vendedor"] || "",
        ciudad_residente: sourceJson["Domicilio Segundo vendedor"] || "",
        estado_civil: sourceJson["Estado civil Segundo vendedor"] || "",
        nacionalidad: "",
        tipoDocumento: "",
        telefono: sourceJson["Numero Celular Segundo vendedor"] || "",
        actividad_economica: sourceJson["Ocupación Segundo vendedor"] || "",
        domicilio: sourceJson["Domicilio Segundo vendedor"] || "",
        direccion_domicilio: sourceJson["Dirección Segundo vendedor"] || "",
        conyugue: {
          nombre: cleanText(sourceJson["En calidad de cónyuge Segundo vendedor"] || ""),
          identificacion: "",
          nacionalidad: "",
          tipoDocumento: "",
          lugar_expedicion: "",
          telefono: "",
          actividad_economica: "",
          domicilio: "",
          direccion_domicilio: ""
        },
        apoderado: {
          nombre: cleanText(sourceJson["Apoderado Segundo vendedor"] || ""),
          domicilio: "",
          tipo_documento: "",
          lugar_expedicion: "",
          identificacion: ""
        }
      });
    }
  }

    const parseCurrency = (value) => {
      return value ? parseFloat(value.replace(/[^0-9.]/g, "").replace(/\.(?=\d{3})/g, "")) : 0;
    };

    const convertirFormatoFecha = (fecha) => {
      return moment(fecha, "DD/MM/YYYY").format("YYYY-MM-DD");
    };

    // JSON mapeado final
    const mappedJson = {
      escritura_numero: "",
      fecha_escritura: convertirFormatoFecha(sourceJson["Fecha pactada escritura"]) || "",
      valor_venta: sourceJson["Valor de la Venta"]
        ? parseFloat(sourceJson["Valor de la Venta"].replace(/[^0-9.]/g, "").replace(/\.(?=\d{3})/g, ""))
        : "0",
      valor_avaluo: "",
      forma_pago: {
        anticipos: [
          {
            valor: parseCurrency(sourceJson["Valor Recibido"]),
          },
        ],
      
        bonos: [
          {
            nombre: sourceJson["Bono 1 Nombre"] || "",
            valor: parseCurrency(sourceJson["Bono 1 Valor"]),
          },
          {
            nombre: sourceJson["Bono 2 Nombre"] || "",
            valor: parseCurrency(sourceJson["Bono 2 Valor"]),
          },
        ].filter(item => item.nombre.trim() !== ""), // Eliminar si el nombre está vacío
      
        subsidios: [
          {
            nombre: sourceJson["Subsidio 1 Nombre"] || "",
            caja_compensacion: sourceJson["Subsidio 1 Caja"] || "",
            valor: parseCurrency(sourceJson["Subsidio 1 Valor"]),
            fecha_acta: sourceJson["Subsidio 1 Fecha Acta"] || "",
            numero_acta: sourceJson["Subsidio 1 Numero Acta"] || "",
          },
          {
            nombre: sourceJson["Subsidio 2 Nombre"] || "",
            caja_compensacion: sourceJson["Subsidio 2 Caja"] || "",
            valor: parseCurrency(sourceJson["Subsidio 2 Valor"]),
            fecha_acta: sourceJson["Subsidio 2 Fecha Acta"] || "",
            numero_acta: sourceJson["Subsidio 2 Numero Acta"] || "",
          },
          {
            nombre: sourceJson["Subsidio 3 Nombre"] || "",
            caja_compensacion: sourceJson["Subsidio 3 Caja"] || "",
            valor: parseCurrency(sourceJson["Subsidio 3 Valor"]),
            fecha_acta: sourceJson["Subsidio 3 Fecha Acta"] || "",
            numero_acta: sourceJson["Subsidio 3 Numero Acta"] || "",
          },
        ].filter(item => item.nombre.trim() !== ""), // Eliminar si el nombre está vacío
      
        creditos: [
          {
            banco: sourceJson["Entidad Bancaria"] || "",
            valor: parseCurrency(sourceJson["Valor del Crédito"]),
            numero_carta: sourceJson["Número Carta Crédito"] || "",
            fecha_carta: sourceJson["Fecha Carta Crédito"] || "",
            uvr: sourceJson["UVR Crédito"] || "",
            plazo: sourceJson["Plazo Crédito"] || "",
            valor_mensual: parseCurrency(sourceJson["Valor Mensual Crédito"]),
          },
        ].filter(item => item.banco.trim() !== ""), // Eliminar si el banco está vacío
      },
      certificado_numero: "",
      fecha_certificado: "",
      derechos_notariales: "",
      numero_resolución: "",
      fecha_resolución: "",
      recaudo_fondo: "",
      recaudo_super: "",
      iva: sourceJson["IVA"] || "",
      pago_retefuente: "",
      nombre_notario: "",
      nombre_notario_firma: "",
      notario_encargado: "",
      linderos: "",
      acredito: sourceJson["Entidad Bancaria"] ? "si" : "no",
      valor_cancelacion_hipoteca: "",
      compradores: compradores,
      vendedores: vendedores,
      inmuebles:[],
    };

    return mappedJson;
  }

  const getDocument = async (path) => {
    try{
    let fileName = path.split("/").pop();
    const payload = { path: path, fileName:fileName };
    const response = await  axios.post(EXTERNAL_API_PATHS.files,payload,{ responseType: 'blob'})

    if (response.status == 200) {
         // Crear un Blob a partir de la respuesta binaria
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Crear una URL para el Blob
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace temporal para la descarga
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName; // Nombre con el que se descargará el archivo

        // Añadir el enlace al DOM y simular un clic
        document.body.appendChild(a);
        a.click();

        // Limpiar el DOM y revocar la URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    } else {
      console.error('Error en la descarga del archivo:', response.statusText);
    }

    
  } catch (error) {
    console.error('Error en la descarga del archivo:', error);
  }
   }
  
  
  return (
    <Box p={4}>
      {/* Selección de Proyecto y Plantilla */}
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          Selección de Proyecto y Plantilla
        </Typography>
        <Box mb={2}>
          <label style={{ marginRight: '10px' }}>Proyectos:</label>
          <select
            value={selectedProject || ''}
            onChange={(e) => setSelectedProject(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          >
            <option value="">Seleccione un proyecto...</option>
            {projects.map((project) => (
              <option key={project.codigo} value={project.codigo}>
                {project.proyecto}
              </option>
            ))}
          </select>
        </Box>
        <Box>
          <label style={{ marginRight: '10px' }}>Plantillas:</label>
          <select
            value={selectedTemplate || ''}
            onChange={(e) => setSelectedTemplate(e.target.value)}
            style={{
              padding: '8px',
              borderRadius: '5px',
              border: '1px solid #ccc',
            }}
          >
            <option value="">Seleccione una plantilla...</option>
            {templates.map((template) => (
              <option key={template.id} value={template.id}>
                {template.nombre_plantilla}
              </option>
            ))}
          </select>
        </Box>
      </Box>

      {/* Formulario */}
      <Formulario initialData={formData} setFormData={setFormData} tiposInmuebles={tiposInmuebles} estadosCiviles={estadosCiviles} nombresNotarios={nombresNotarios} nombresNotariosFirma = {nombresNotariosFirma} bonos={bonos} subsidios={subsidios} typeIds={typeIds} />

      {/* Botón para Generar Documento */}
      <Box mt={4} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateDocument}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '10px' }} />
              Generando Documento...
            </>
          ) : (
            'Generar Documento'
          )}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveData}
          disabled={isLoading2}
        >
          {isLoading2 ? (
            <>
              <CircularProgress size={20} style={{ marginRight: '10px' }} />
              Guardando Información...
            </>
          ) : (
            'Guardar Datos'
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default App;
