import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core/";
import { Autocomplete } from '@material-ui/lab';
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import { EXTERNAL_API_PATHS } from "../../../utils/constants";

// Función auxiliar para actualizar valores anidados
const setNestedValue = (obj, path, value) => {
  const keys = path.replace(/\[(\d+)\]/g, ".$1").split(".");
  let current = { ...obj };
  let temp = current;
  keys.forEach((key, index) => {
    if (index === keys.length - 1) {
      temp[key] = value;
    } else {
      temp[key] = Array.isArray(temp[key]) ? [...temp[key]] : { ...temp[key] };
      temp = temp[key];
    }
  });
  return current;
};
const Formulario = ({ initialData = {}, setFormData, tiposInmuebles, estadosCiviles, nombresNotarios, nombresNotariosFirma, bonos, subsidios, typeIds }) => {
  const [form, setForm] = useState({
    escritura_numero: "",
    fecha_escritura: "",
    certificado_numero: "",
    fecha_certificado: "",
    numero_resolución: "",
    fecha_resolución: "",
    apartamento_numero: "",
    notario_encargado: "",
    nombre_notario: "",
    nombre_notario_firma: "",
    valor_venta: "",
    valor_avaluo: "",
    valor_anticipo: "",
    derechos_notariales: "",
    recaudo_fondo: "",
    recaudo_super: "",
    iva: "",
    pago_retefuente: "",
    linderos: "",
    acredito: "",
    valor_cancelacion_hipoteca: "",
    banco_credito: "",
    valor_credito: "",
    subsidio: "",
    nombre_caja_compensacion: "",
    valor_subsidio_numeros: "",
    fecha_acta_subsidio: "",
    numero_acta_subsidio: "",
    compradores: [],
    vendedores: [],
    inmuebles: [],
    credito: {},
    bono: "",
    forma_pago: { bonos: [], subsidios: [], creditos: [] },
  });

  const [inmuebles, setInmuebles] = useState([]); // Estado para almacenar los datos de inmuebles
  const [selectedInmuebles, setSelectedInmuebles] = useState([]); // Estado para las matriculas seleccionadas
  const [loading, setLoading] = useState(false); // Estado de carga
  const [showSelectInmuebles, setShowSelectInmuebles] = useState(true);
  const [showSubsidioFields, setShowSubsidioFields] = useState(false);
  const [search, setSearch] = useState("");
  const [selectOptions, setSelectOptions] = useState({
    bonos: [
      { value: "PROMOCION", label: "Promoción" },
      { value: "EMPLEADO", label: "Empleado" },
    ],
    subsidio: [
      { value: "COMFENALCO", label: "Comfenalco" },
      { value: "COMFANDI", label: "Comfandi" },
      { value: "MICASAYA", label: "Mi Casa Ya" },
    ],
    tipoDocumento: [
      { value: "cc", label: "Cédula de Ciudadanía" },
      { value: "ce", label: "Cédula de Extranjería" },
      { value: "pasaporte", label: "Pasaporte" },
    ],
    nombre_notario: [
      { value: "HÉCTOR MARIO GARCÉS PADILLA", label: "HÉCTOR MARIO GARCÉS PADILLA" },
      { value: "SANDRA PATRICIA TOBAR PEREZ", label: "SANDRA PATRICIA TOBAR PEREZ" },
      { value: "HELEN ALEJANDRA BROWN PITTO", label: "HELEN ALEJANDRA BROWN PITTO" },
      { value: "SEBASTIÁN CADENA VALENCIA", label: "SEBASTIÁN CADENA VALENCIA" },
    ],
    nombre_notario_firma: [
      { value: "DOCTOR HÉCTOR MARIO GARCÉS PADILLA", label: "DOCTOR HÉCTOR MARIO GARCÉS PADILLA" },
      { value: "DOCTORA SANDRA PATRICIA TOBAR PEREZ", label: "DOCTORA SANDRA PATRICIA TOBAR PEREZ" },
      { value: "DOCTORA HELEN ALEJANDRA BROWN PITTO", label: "DOCTORA HELEN ALEJANDRA BROWN PITTO" },
      { value: "DOCTOR SEBASTIÁN CADENA VALENCIA", label: "DOCTOR SEBASTIÁN CADENA VALENCIA" },
    ],
    notario_encargado: [
      { value: "si", label: "Si" },
      { value: "no", label: "No" },
    ],
    acredito: [
      { value: "si", label: "Si" },
      { value: "no", label: "No" },
    ],
    apoderado: [
      { value: "si", label: "Si" },
      { value: "no", label: "No" },
    ],
  })
  const filteredInmuebles = inmuebles.filter(
    (inmueble) =>
      !selectedInmuebles.includes(inmueble.id) &&
      inmueble.numeroMatricula.toLowerCase().includes(search.toLowerCase())
  );
  const currencyFormatter = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
  });
  const parseCurrency = (value) => {
    return value.replace(/[^0-9]/g, ""); // Elimina caracteres no numéricos
  };
  const formatCurrency = (value) => {
    const numberValue = Number(value) || 0;
    return currencyFormatter.format(numberValue);
};
  const handleCurrencyChange = (path, value) => {
    if(value == null) return;
    const numericValue = parseCurrency(value);
    handleChange(path, numericValue); // Guarda solo números en el estado
  };
  useEffect(() => {
    if (Object.keys(initialData).length > 0) {
      setForm(initialData);
    }
  }, [initialData]);
  useEffect(() => {
    if (form.subsidio === 'si') {
      setShowSubsidioFields(true);
    } else if (form.subsidio === 'no') {
      setShowSubsidioFields(false);
      setForm((prevForm) => ({
        ...prevForm,
        nombre_caja_compensacion: '',
        valor_subsidio_numeros: '',
        fecha_acta_subsidio: '',
        numero_acta_subsidio: '',
      }));
    }
  }, [form.subsidio]);

  const handleChange = (path, value) => {
    const updatedForm = setNestedValue(form, path, value);
    setForm(updatedForm);
  if (path.includes("inmuebles")) {
    setSelectedInmuebles(Array.isArray(value) ? value : []);
  }
    setFormData(updatedForm)
  };

  const addComprador = () => {
    const newComprador = {
      nombre: "",
      cedula: "",
      ciudad_residente: "",
      estado_civil: "",
      nacionalidad: "",
      tipoDocumento: "",
      lugar_expedicion: "",
      telefono: "",
      actividad_economica: "",
      domicilio: "",
      direccion_domicilio: "",
      conyugue: {
        nombre: "",
        identificacion: "",
        nacionalidad: "",
        tipoDocumento: "",
        lugar_expedicion: "",
        telefono: "",
        actividad_economica: "",
        domicilio: "",
        direccion_domicilio: "",
      },
      apoderado: {
        nombre: "",
        domicilio: "",
        tipo_documento: "",
        lugar_expedicion: "",
        identificacion: "",
      },
    };
    setForm((prev) => ({
      ...prev,
      compradores: [...(Array.isArray(prev.compradores) ? prev.compradores : []), newComprador],
    }));
  };
  const addVendedor = () => {
    const newVendedor = {
      nombre: "",
      cedula: "",
      ciudad_residente: "",
      estado_civil: "",
      nacionalidad: "",
      tipoDocumento: "",
      lugar_expedicion: "",
      telefono: "",
      actividad_economica: "",
      domicilio: "",
      direccion_domicilio: "",
      conyugue: {
        nombre: "",
        identificacion: "",
        lugar_expedicion: "",
        nacionalidad: "",
        tipoDocumento: "",
        telefono: "",
        actividad_economica: "",
        domicilio: "",
        direccion_domicilio: "",
      },
      apoderado: {
        nombre: "",
        domicilio: "",
        tipo_documento: "",
        lugar_expedicion: "",
        identificacion: "",
      },
    };
    setForm((prev) => ({
      ...prev,
      vendedores: [...(Array.isArray(prev.vendedores) ? prev.vendedores : []), newVendedor],
    }));
  };

  const addInmueble = () => {
    setShowSelectInmuebles(true);
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    if (!selectedInmuebles.includes(selectedId)) {
      const selectedObject = inmuebles.find((item) => item.id === selectedId);
      if (selectedObject) {
        const formattedInmueble = {
          numero_matricula_inmobiliaria: selectedObject.numeroMatricula,
          tipoInmueble: selectedObject.id_tipo_inmueble,
          nombre: tiposInmuebles.find(p => p.id === selectedObject.id_tipo_inmueble)?.name ?? '',
          numero: selectedObject.numero_inmueble || "",
          coeficiente: selectedObject.coeficiente || "",
          linderos: selectedObject.linderos || "",
          avaluo_catastral_prorrata: selectedObject.avaluo || ""
        }
        const newInmuebles = [formattedInmueble, ...(Array.isArray(form.inmuebles) ? form.inmuebles : [])];
        handleChange("inmuebles", newInmuebles);
        setSelectedInmuebles((prev) => [selectedId, ...prev]);
      }
    }
    setShowSelectInmuebles(false); // Ocultar select después de elegir
  };

  const addBono = () => {
    const newBono = {
      nombre: "",
      valor: "",
    };
    const updatedBonos = [...(form?.forma_pago?.bonos || []), newBono];
    handleChange("forma_pago.bonos", updatedBonos);
  };

  const addSubsidio = () => {
    const newSubsidio = {
      nombre: "",
      valor: "",
      nombre: "",
      caja_compensacion: "",
      valor: "",
      fecha_acta: "",
      numero_acta: "",
    }
    const updatedSubsidios = [...(form?.forma_pago?.subsidios || []), newSubsidio];
    handleChange("forma_pago.subsidios", updatedSubsidios);
  };

  // const handleSubsidioChange = (value) => {
  //   if (value !== "si" && value !== "no") return;
  //     if (value === "no") {
  //       handleChange('nombre_caja_compensacion', "");
  //       handleChange('valor_subsidio_numeros', '');
  //       handleChange('fecha_acta_subsidio', '');
  //       handleChange('numero_acta_subsidio', '');
  //     }
  //   handleChange("subsidio", value);
  //   setShowSubsidioFields(value === "si");
  // };

  const handleEstadoCivilChange = (value, compradorIndex) => {
    const civilStatus = estadosCiviles.find(el => el.value === value)?.umh || 0;
    // umh: unión marital de hecho (cualquiera que requiera conyugue)
    const isMarried = civilStatus === 1;
    setForm((prevForm) => {
      const newCompradores = prevForm.compradores.map((comprador, index) => {
        if (index === compradorIndex) {
          if (!isMarried) {
            const { conyugue, ...rest } = comprador;
            return rest;
          }
          return { ...comprador, estado_civil: value };
        }
        return comprador;
      });
      return {
        ...prevForm,
        compradores: newCompradores,
      };
    });
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (!isMarried) {
        delete newData.compradores?.[compradorIndex]?.conyugue;
      }
      return newData;
    });
    handleChange(`compradores[${compradorIndex}].estado_civil`, value);
  };
  const handleEstadoCivilSellerChange = (value, vendedorIndex) => {
    const civilStatus = estadosCiviles.find(el => el.value === value)?.umh || 0;
    // umh: unión marital de hecho (cualquiera que requiera conyugue)
    const isMarried = civilStatus === 1;
    setForm((prevForm) => {
      const newCompradores = prevForm.vendedores.map((comprador, index) => {
        if (index === vendedorIndex) {
          if (!isMarried) {
            const { conyugue, ...rest } = comprador;
            return rest;
          }
          return { ...comprador, estado_civil: value };
        }
        return comprador;
      });
      return {
        ...prevForm,
        vendedores: newCompradores,
      };
    });
    setFormData((prevData) => {
      const newData = { ...prevData };
      if (!isMarried) {
        delete newData.vendedores?.[vendedorIndex]?.conyugue;
      }
      return newData;
    });
    handleChange(`vendedores[${vendedorIndex}].estado_civil`, value);
  };
  useEffect(() => {
    setForm((prevForm) => {
      const newCompradores = prevForm.compradores.map((comprador) => {
        const estado = estadosCiviles.find(el => el.value === comprador.estado_civil);
        const tieneUMH = estado?.umh === 1;
        return tieneUMH ? { ...comprador, conyugue: comprador.conyugue || {} } : comprador;
      });
      return { ...prevForm, compradores: newCompradores };
    });
  }, []);
  const handleApoderadoChange = (value, compradorIndex) => {
    setForm((prevForm) => {
      const newCompradores = prevForm.compradores.map((comprador, index) => {
        if (index === compradorIndex) {
          if (value === "no") {
            const { apoderado, ...rest } = comprador;
            return rest;
          }
        }
        return comprador;
      });
      return {
        ...prevForm,
        compradores: newCompradores,
      };
    });
    setFormData((prevData) => {
      const newData = { ...prevData };
      delete newData.compradores?.[compradorIndex]?.apoderado;
      return newData;
    });
    handleChange(`compradores[${compradorIndex}].apoderado_estado`, value);
  };
  const handleApoderadoChangeSeller = (value, vendedorIndex) => {
    setForm((prevForm) => {
      const newVendedores = prevForm.vendedores.map((comprador, index) => {
        if (index === vendedorIndex) {
          if (value === "no") {
            const { apoderado, ...rest } = comprador;
            return rest;
          }
        }
        return comprador;
      });
      return {
        ...prevForm,
        vendedores: newVendedores,
      };
    });
    setFormData((prevData) => {
      const newData = { ...prevData };
      delete newData.vendedores?.[vendedorIndex]?.apoderado;
      return newData;
    });
    handleChange(`vendedores[${vendedorIndex}].apoderado_estado`, value);
  };

  const handleNotarioEncargadoChange = (value) => {
    setForm((prevForm) => ({
      ...prevForm,
      notario_encargado: value,
      nombre_notario: value === "no" ? "HÉCTOR MARIO GARCÉS PADILLA" : "",
      nombre_notario_firma: value === "no" ? "DOCTOR HÉCTOR MARIO GARCÉS PADILLA" : "",
    }));
  };

  const removeInmueble = (index) => {
    const newInmuebles = form.inmuebles.filter((_, i) => i !== index);
    handleChange("inmuebles", newInmuebles);
  };
  const removeBono = (index) => {
    const newBono = form.forma_pago.bonos.filter((_, i) => i !== index);
    handleChange("forma_pago.bonos", newBono);
  };
  const removeSubsidio = (index) => {
    const newSubsidio = form.forma_pago.subsidios.filter((_, i) => i !== index);
    handleChange("forma_pago.subsidios", newSubsidio);
  };

    useEffect(() => {
      const fetchInmuebles = async () => {
        setLoading(true);
        axios
          .get(EXTERNAL_API_PATHS["inmuebles"])
          .then((res) => {
            const formattedData = res.data.map((item) => ({
              id: item.id,
              numeroMatricula: item.matricula,
              numeroPredial: item.numero_predial,
              numeroCatastral: item.numero_catastral,
              linderos: item.linderos,
              area: item.area,
              direccion: item.direccion,
              avaluo: item.avaluo,
              coeficiente: item.coeficiente_ph,
              proyectoId: item.proyecto?.codigo,
              proyectoName: item.proyecto?.proyecto,
              id_tipo_inmueble: item.id_tipo_inmueble,
              numero_inmueble: item.numero_inmueble,
            }));
            setInmuebles(formattedData);
          })
          .finally(() => setLoading(false));
      };
      fetchInmuebles();
    }, []);

// filtrar inmuebles ya elegidos
    useEffect(() => {
      if (initialData?.inmuebles?.length > 0) {
        const validIds = initialData.inmuebles
          .filter(inmueble => inmueble.id)
          .map(inmueble => inmueble.id);
        setSelectedInmuebles(validIds);
      }
    }, [initialData]);

  const removeComprador = (index) => {
    const newCompradores = form.compradores.filter((_, i) => i !== index);
    handleChange(`compradores`, newCompradores);
  };
  const removeVendedor = (index) => {
    const newVendedores = form.vendedores.filter((_, i) => i !== index);
    handleChange(`vendedores`, newVendedores);
  };


  return (
    <Box p={1}>
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Typography variant="h4" gutterBottom>
          Datos Básicos
        </Typography>

      {/* Sección Datos básicos */}
      <Grid container spacing={2}>
      {[
        { label: "Número Escritura", path: "escritura_numero", type: "number" },
        { label: "Fecha Escritura", path: "fecha_escritura", type: "date" },
        { label: "Certificado Número", path: "certificado_numero", type: "number" },
        { label: "Fecha Certificado", path: "fecha_certificado", type: "date" },
        { label: "Número Resolución", path: "numero_resolución", type: "number" },
        { label: "Fecha Resolución", path: "fecha_resolución", type: "date" },
        // { label: "Notario Encargado", path: "notario_encargado", type: "select", options: selectOptions.notario_encargado, onChange: handleNotarioEncargadoChange },
        // ...(form.notario_encargado === "si" || form.notario_encargado === "no"
        // ? [
        { label: "Nombre Notario", path: "nombre_notario", type: "select", options: nombresNotarios },
          { label: "Nombre Notario Firma", path: "nombre_notario_firma", type: "select", options: nombresNotariosFirma },
        // ]
        // : [])
      ].map((field, index) => (
        <Grid item xs={12} sm={6} key={index}>
          {field.type === "select" ? (
            <TextField
              select
              label={field.label}
              value={form[field.path] || ""}
              onChange={(e) => field.onChange ? field.onChange(e.target.value) : handleChange(field.path, e.target.value)}
              fullWidth
            >
              {field.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <TextField
              label={field.label}
              type={field.type}
              value={form[field.path] || ""}
              onChange={(e) => handleChange(field.path, e.target.value)}
              fullWidth
              InputLabelProps={field.type === "date" ? { shrink: true } : {}}
            />
          )}
        </Grid>
      ))}
      </Grid>
      </Box>
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Typography variant="h4" gutterBottom>
          Valores
        </Typography>
      {/* Sección Valores */}
        <Grid container spacing={2}>
          {[
            { label: "Valor Venta", path: "valor_venta", type: "number" },
            { label: "Valor Avalúo", path: "valor_avaluo", type: "number" },
            { label: "Valor Anticipo", path: "valor_anticipo", type: "number" },
            { label: "Derechos Notariales", path: "derechos_notariales", type: "number" },
            { label: "Recaudo Fondo", path: "recaudo_fondo", type: "number" },
            { label: "Recaudo Super", path: "recaudo_super", type: "number" },
            { label: "IVA", path: "iva", type: "number" },
            { label: "Pago Retefuente", path: "pago_retefuente", type: "number" },
            { label: "Valor Cancelación Hipoteca",path: "valor_cancelacion_hipoteca",type: "number"},
          ].map((field, index) => (
            <Grid item xs={12} sm={6} key={index}>
              {field.type === "select" ? (
                <TextField
                  select
                  label={field.label}
                  value={form[field.path] || ""}
                  onChange={(e) => handleChange(field.path, e.target.value)}
                  fullWidth
                >
                </TextField>
              ) : (
                <TextField
                  label={field.label}
                  value={formatCurrency(form[field.path])}
                  onChange={(e) => handleCurrencyChange(field.path, e.target.value)}
                  fullWidth
                />
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* Sección Forma de Pago */}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Typography variant="h4" gutterBottom>
          Forma de Pago
        </Typography>
        <Grid container spacing={2}>
          {/* Anticipos */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Valor Anticipo"
              value={formatCurrency(form.forma_pago?.anticipos?.[0]?.valor ?? 0)}
              onChange={(e) => handleCurrencyChange("forma_pago.anticipos.0.valor", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={addBono}
              style={{ marginBottom: "10px" }}
            >
              Agregar bono
            </Button>
          </Grid>
          {/* Bonos */}
          {(form.forma_pago?.bonos || []).map((bono, index) => (
            <Box key={index} p={2} style={{ border: "1px solid #ccc", borderRadius: "5px", marginBottom: "10px", width: "100%" }}>
              <Typography variant="subtitle1" gutterBottom>
                Bono {index + 1}
              </Typography>
              <Grid container spacing={2}>
                {["nombre", "valor"].map((field) => (
                  <Grid item xs={12} sm={6} key={field}>
                    <TextField
                      select={field === "nombre"}
                      label={field === "nombre" ? "Nombre Bono" : "Valor Bono"}
                      value={field === "valor" ? formatCurrency(bono[field]) : bono[field] || ""}
                      onChange={(e) => {
                        if (field === "valor") {
                          const rawValue = e.target.value.replace(/[^0-9]/g, ""); // Solo números
                          handleChange(`forma_pago.bonos.${index}.${field}`, rawValue ? parseFloat(rawValue) : "");
                        } else {
                          handleChange(`forma_pago.bonos.${index}.${field}`, e.target.value); // Guardar string directamente
                        }
                      }}
                      fullWidth
                    >
                      {field === "nombre" &&
                        bonos.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => removeBono(index)}
                    style={{ marginTop: "20px" }}
                  >
                    <DeleteIcon />
                    Eliminar Bono
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ))}

         {/* Subsidios */}
          {<Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={addSubsidio}
                style={{ marginBottom: "10px" }}
              >
                Agregar subsidio
              </Button>
            </Grid>
          }
          {(form.forma_pago?.subsidios || []).map((subsidio, index) => (
            <Box
              p={2}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "column",
              }}
              key={index}
            >
              <Typography variant="subtitle1" gutterBottom>
                Subsidio {index + 1}
              </Typography>
              <Grid container spacing={2} key={index}>
                {[
                  { key: "nombre", label: "Nombre Subsidio", select: true, options: subsidios },
                  { key: "caja_compensacion", label: "Caja Compensación" },
                  { key: "valor", label: "Valor Subsidio" },
                  { key: "fecha_acta", label: "Fecha Acta", type: "date" },
                  { key: "numero_acta", label: "Número Acta" },
                ].map(({ key, label, select, options, type }) => (
                  <Grid item xs={12} sm={6} key={key}>
                    <TextField
                      select={!!select}
                      label={label}
                      type={type || "text"}
                      value={key === "valor" ? formatCurrency(subsidio[key]) : subsidio[key] || ""}
                      onChange={(e) => {
                        if (key === "valor") {
                          const rawValue = e.target.value.replace(/[^0-9]/g, ""); // Solo números
                          handleChange(`forma_pago.subsidios.${index}.${key}`, rawValue ? parseFloat(rawValue) : "");
                        } else {
                          handleChange(`forma_pago.subsidios.${index}.${key}`, e.target.value);
                        }
                      }}
                      fullWidth
                      InputLabelProps={type === "date" ? { shrink: true } : undefined}
                    >
                    {select &&
                      options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                ))}
              </Grid>
              <Box style={{ marginTop: "20px", alignSelf: "flex-start" }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => removeSubsidio(index)}
                >
                  <DeleteIcon />
                  Eliminar Subsidio
                </Button>
              </Box>
            </Box>
          ))}
          {/* Créditos */}
          {(form.forma_pago?.creditos || []).map((credito, index) => (
            <Box
              key={index}
              p={2}
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginBottom: "10px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                minHeight: "auto",
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Crédito
              </Typography>
              <Grid container spacing={2}>
                {[
                  { field: "banco", label: "Banco" },
                  { field: "valor", label: "Valor Crédito" },
                  { field: "numero_carta", label: "Número Carta" },
                  { field: "fecha_carta", label: "Fecha Carta", type: "date" },
                  { field: "uvr", label: "UVR" },
                  { field: "plazo", label: "Plazo" },
                  { field: "valor_mensual", label: "Valor Mensual" },
                ].map(({ field, label, type }) => (
                  <Grid item xs={12} sm={6} key={field}>
                    <TextField
                      label={label}
                      type={type || "text"}
                      value={["valor", "valor_mensual"].includes(field) ? formatCurrency(credito[field]) : credito[field] || ""}
                      onChange={(e) => {
                        if (["valor", "valor_mensual"].includes(field)) {
                          const rawValue = e.target.value.replace(/[^0-9]/g, ""); // Solo números
                          handleChange(`forma_pago.creditos.${index}.${field}`, rawValue ? parseFloat(rawValue) : "");
                        } else {
                          handleChange(`forma_pago.creditos.${index}.${field}`, e.target.value);
                        }
                      }}
                      fullWidth
                      InputLabelProps={type === "date" ? { shrink: true } : {}}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Grid>
      </Box>

      {/* Sección Inmuebles*/}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Box mt={2}>
          <Typography variant="h5">Inmuebles</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={addInmueble}
            style={{ marginBottom: "20px" }}
          >
            Agregar Inmueble
          </Button>

            {/* NUEVA SECCIÓN DE INMUEBLES */}
            {showSelectInmuebles && (
              <Box mt={1}>
                <Box mb={3} p={2} style={{ border: "1px solid #ccc", maxWidth: "800px" }}>
                <Typography variant="h6" gutterBottom>
                  Seleccionar inmueble
                </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Autocomplete
                          options={filteredInmuebles}
                          getOptionLabel={(option) => option.numeroMatricula}
                          onChange={(event, newValue) => handleSelectChange({ target: { value: newValue?.id || "" } })}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Buscar inmueble"
                              variant="outlined"
                              size="small"
                              sx={{ minWidth: 300 }}
                            />
                          )}
                          style={{ width: 300, marginTop: "20px" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          {form.inmuebles?.map((inmueble, index) => (
            <Box key={index} mb={3} p={2} style={{ border: "1px solid #ccc" }}>
              <Typography variant="h6">Inmueble {index + 1}</Typography>
              <Grid container spacing={2}>
                {[
                  { label: "Número Matrícula Inmobiliaria", path: "numero_matricula_inmobiliaria", type: "text" },
                  { label: "Tipo de inmueble", path: "nombre", type: "select", options: tiposInmuebles },
                  { label: "Número", path: "numero", type: "text" },
                  { label: "Coeficiente", path: "coeficiente", type: "number" },
                  { label: "Linderos", path: "linderos", type: "text" },
                  { label: "Avalúo Catastral Prorrata", path: "avaluo_catastral_prorrata", type: "number" },
                ].map((field, i) => (
                  <Grid item xs={6} key={i}>
                    {field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        value={inmueble?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`inmuebles[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option.id} value={option.name}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        type={field.type}
                        value={inmueble?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`inmuebles[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeInmueble(index)}
                style={{ marginTop: "20px" }}
              >
                <DeleteIcon />
                Eliminar Inmueble
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      {/* Sección Compradores */}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Box mt={4}>
          <Typography variant="h5">Compradores</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={addComprador}
            style={{ marginBottom: "20px" }}
          >
            Agregar Comprador
          </Button>
          {form.compradores?.map((comprador, index) => (
            <Box key={index} mb={3} p={2} style={{ border: "1px solid #ccc" }}>
              <Typography variant="h6">Comprador {index + 1}</Typography>
              <Grid container spacing={2}>
                {[
                  { label: "Nombre", path: "nombre", type: "text" },
                  { label: "Cédula", path: "cedula", type: "number" },
                  { label: "Ciudad Residente", path: "ciudad_residente", type: "text" },
                  { label: "Estado Civil", path: "estado_civil", type: "select", options: estadosCiviles },
                  { label: "Nacionalidad", path: "nacionalidad", type: "text" },
                  { label: "Tipo Documento", path: "tipoDocumento", type: "select", options: typeIds},
                  { label: "Lugar expedición", path: "lugar_expedicion", type: "text" },
                  { label: "Teléfono", path: "telefono", type: "number" },
                  { label: "Actividad Económica", path: "actividad_economica", type: "text" },
                  { label: "Domicilio", path: "domicilio", type: "text" },
                  { label: "Dirección Domicilio", path: "direccion_domicilio", type: "text" },
                  { label: "Apoderado", path: "apoderado_estado", type: "select", options: selectOptions.apoderado },
                ].map((field, i) => (
                  <Grid item xs={6} key={i}>
                    {field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        value={comprador?.[field.path] || ""}
                        onChange={(e) => {
                          if (field.path === "estado_civil") {
                            handleEstadoCivilChange(e.target.value, index);
                          } else if (field.path === "apoderado_estado") {
                            handleApoderadoChange(e.target.value, index);
                          } else {
                            handleChange(`compradores[${index}].${field.path}`, e.target.value);
                          }
                        }}
                        fullWidth
                      >
                      {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        type={field.type}
                        value={comprador?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`compradores[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}

                {(() => {
                  const estado = estadosCiviles.find(el => el.value === comprador?.estado_civil);
                  return estado?.umh === 1;
                })() && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Cónyuge</Typography>
                    </Grid>

                    {[
                      { label: "Nombre", path: "nombre", type: "text" },
                      { label: "Identificación", path: "identificacion", type: "number" },
                      { label: "Nacionalidad", path: "nacionalidad", type: "text" },
                      { label: "Tipo Documento", path: "tipoDocumento", type: "select", options: typeIds },
                      { label: "Lugar expedición", path: "lugar_expedicion", type: "text" },
                      { label: "Teléfono", path: "telefono", type: "number" },
                      { label: "Actividad Económica", path: "actividad_economica", type: "text" },
                      { label: "Domicilio", path: "domicilio", type: "text" },
                      { label: "Dirección Domicilio", path: "direccion_domicilio", type: "text" },
                    ].map((field, i) => (
                      <Grid item xs={6} key={i}>
                        {field.type === "select" ? (
                          <TextField
                            select
                            label={field.label}
                            value={comprador.conyugue?.[field.path] || ""}
                            onChange={(e) =>
                              handleChange(`compradores[${index}].conyugue.${field.path}`, e.target.value)
                            }
                            fullWidth
                          >
                          {field.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <TextField
                            label={field.label}
                            type={field.type}
                            value={comprador.conyugue?.[field.path] || ""}
                            onChange={(e) =>
                              handleChange(`compradores[${index}].conyugue.${field.path}`, e.target.value)
                            }
                            fullWidth
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                )}

                {/* Sección Apoderado */}
                {comprador?.apoderado_estado === "si" && (
                  <Grid item xs={12}>
                    <Typography variant="h6">Apoderado</Typography>
                    <Grid container spacing={2}>
                      {[
                        { label: "Nombre", path: "nombre", type: "text" },
                        { label: "Domicilio", path: "domicilio", type: "text" },
                        { label: "Tipo Documento", path: "tipo_documento", type: "select", options: typeIds },
                        { label: "Lugar expedición", path: "lugar_expedicion", type: "text" },
                        { label: "Identificación", path: "identificacion", type: "number" },
                      ].map((field, i) => (
                        <Grid item xs={12} sm={6} key={i}>
                          {field.type === "select" ? (
                            <TextField
                              select
                              label={field.label}
                              value={comprador.apoderado?.[field.path] || ""}
                              onChange={(e) =>
                                handleChange(`compradores[${index}].apoderado.${field.path}`, e.target.value)
                              }
                              fullWidth
                            >
                              {field.options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              label={field.label}
                              type={field.type}
                              value={comprador.apoderado?.[field.path] || ""}
                              onChange={(e) =>
                                handleChange(`compradores[${index}].apoderado.${field.path}`, e.target.value)
                              }
                              fullWidth
                            />
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeComprador(index)}
                style={{ marginTop: "20px" }}
              >
                <DeleteIcon />
                Eliminar Comprador
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      {/* Sección Vendedores */}
      <Box p={4} style={{ border: "1px solid #ccc", borderRadius: "5px", margin: "5px" }}>
        <Box mt={4}>
          <Typography variant="h5">Vendedores</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={addVendedor}
            style={{ marginBottom: "20px" }}
          >
            Agregar Vendedor
          </Button>
          {form.vendedores?.map((vendedor, index) => (
            <Box key={index} mb={3} p={2} style={{ border: "1px solid #ccc" }}>
              <Typography variant="h6">Vendedor {index + 1}</Typography>
              <Grid container spacing={2}>
                {[
                  { label: "Nombre", path: "nombre", type: "text" },
                  { label: "Cédula", path: "cedula", type: "number" },
                  { label: "Ciudad Residente", path: "ciudad_residente", type: "text" },
                  { label: "Estado Civil", path: "estado_civil", type: "select", options: estadosCiviles },
                  { label: "Nacionalidad", path: "nacionalidad", type: "text" },
                  { label: "Tipo Documento", path: "tipoDocumento", type: "select", options: typeIds},
                  { label: "Lugar expedición", path: "lugar_expedicion", type: "text" },
                  { label: "Teléfono", path: "telefono", type: "number" },
                  { label: "Actividad Económica", path: "actividad_economica", type: "text" },
                  { label: "Domicilio", path: "domicilio", type: "text" },
                  { label: "Dirección Domicilio", path: "direccion_domicilio", type: "text" },
                  { label: "Apoderado", path: "apoderado_estado", type: "select", options: selectOptions.apoderado },
                ].map((field, i) => (
                  <Grid item xs={6} key={i}>
                    {field.type === "select" ? (
                      <TextField
                        select
                        label={field.label}
                        value={vendedor?.[field.path] || ""}
                        onChange={(e) => {
                          if (field.path === "estado_civil") {
                            handleEstadoCivilSellerChange(e.target.value, index);
                          } else if (field.path === "apoderado_estado") {
                            handleApoderadoChangeSeller(e.target.value, index);
                          } else {
                            handleChange(`vendedores[${index}].${field.path}`, e.target.value);
                          }
                        }}
                        fullWidth
                      >
                      {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        label={field.label}
                        type={field.type}
                        value={vendedor?.[field.path] || ""}
                        onChange={(e) =>
                          handleChange(`vendedores[${index}].${field.path}`, e.target.value)
                        }
                        fullWidth
                      />
                    )}
                  </Grid>
                ))}

                {(() => {
                  const estado = estadosCiviles.find(el => el.value === vendedor?.estado_civil);
                  return estado?.umh === 1;
                })() && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">Cónyuge</Typography>
                    </Grid>

                    {[
                      { label: "Nombre", path: "nombre", type: "text" },
                      { label: "Identificación", path: "identificacion", type: "number" },
                      { label: "Nacionalidad", path: "nacionalidad", type: "text" },
                      { label: "Tipo Documento", path: "tipoDocumento", type: "select", options: typeIds },
                      { label: "Lugar expedición", path: "lugar_expedicion", type: "text" },
                      { label: "Teléfono", path: "telefono", type: "number" },
                      { label: "Actividad Económica", path: "actividad_economica", type: "text" },
                      { label: "Domicilio", path: "domicilio", type: "text" },
                      { label: "Dirección Domicilio", path: "direccion_domicilio", type: "text" },
                    ].map((field, i) => (
                      <Grid item xs={6} key={i}>
                        {field.type === "select" ? (
                          <TextField
                            select
                            label={field.label}
                            value={vendedor.conyugue?.[field.path] || ""}
                            onChange={(e) =>
                              handleChange(`vendedores[${index}].conyugue.${field.path}`, e.target.value)
                            }
                            fullWidth
                          >
                          {field.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        ) : (
                          <TextField
                            label={field.label}
                            type={field.type}
                            value={vendedor.conyugue?.[field.path] || ""}
                            onChange={(e) =>
                              handleChange(`vendedores[${index}].conyugue.${field.path}`, e.target.value)
                            }
                            fullWidth
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                )}

                {/* Sección Apoderado */}
                {vendedor?.apoderado_estado === "si" && (
                  <Grid item xs={12}>
                    <Typography variant="h6">Apoderado</Typography>
                    <Grid container spacing={2}>
                      {[
                        { label: "Nombre", path: "nombre", type: "text" },
                        { label: "Domicilio", path: "domicilio", type: "text" },
                        { label: "Tipo Documento", path: "tipo_documento", type: "select", options: typeIds },
                        { label: "Lugar expedición", path: "lugar_expedicion", type: "text" },
                        { label: "Identificación", path: "identificacion", type: "number" },
                      ].map((field, i) => (
                        <Grid item xs={12} sm={6} key={i}>
                          {field.type === "select" ? (
                            <TextField
                              select
                              label={field.label}
                              value={vendedor.apoderado?.[field.path] || ""}
                              onChange={(e) =>
                                handleChange(`vendedores[${index}].apoderado.${field.path}`, e.target.value)
                              }
                              fullWidth
                            >
                              {field.options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          ) : (
                            <TextField
                              label={field.label}
                              type={field.type}
                              value={vendedor.apoderado?.[field.path] || ""}
                              onChange={(e) =>
                                handleChange(`vendedores[${index}].apoderado.${field.path}`, e.target.value)
                              }
                              fullWidth
                            />
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeVendedor(index)}
                style={{ marginTop: "20px" }}
              >
                <DeleteIcon />
                Eliminar Vendedor
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Formulario;